import React, { useEffect, useState } from "react";
import Chip from "@mui/material/Chip";
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
import WhiteBox from "./components/Whitebox";
import Title from "./components/Title";

function App() {
  const [allTagsData, setAllTags] = useState([]);
  const [enabledTags, setEnabledTags] = useState([]);
  const { id } = useParams(); // Assuming 'id' is the property_ID needed

  useEffect(() => {
    const propertyTagsSample = [
      { tag_name: 'Important', tag_name_ID: '001' },
      { tag_name: 'Gary', tag_name_ID: '002' },
      { tag_name: 'Wholesale', tag_name_ID: '003' },
      { tag_name: 'Bankruptcy', tag_name_ID: '004' },
      { tag_name: 'Motivated Seller', tag_name_ID: '005' },
      { tag_name: 'Uninhabitable', tag_name_ID: '006' },
      { tag_name: 'Dead Lead', tag_name_ID: '007' },
      { tag_name: 'New Numbers', tag_name_ID: '008' },
      { tag_name: 'Active Lead', tag_name_ID: '009' },
      { tag_name: 'Closed Lead', tag_name_ID: '010' },
      { tag_name: 'Sam', tag_name_ID: '011' },
      { tag_name: 'Bill', tag_name_ID: '012' },
      { tag_name: 'Short Sale', tag_name_ID: '013' },
      { tag_name: 'Followup', tag_name_ID: '014' },
      { tag_name: 'Auction', tag_name_ID: '015' },
      { tag_name: 'Probate', tag_name_ID: '016' },
      { tag_name: 'Multi-family', tag_name_ID: '017' },
      { tag_name: 'Unauthorized Occupant', tag_name_ID: '018' },
      { tag_name: 'Number Lookup', tag_name_ID: '019' },
      { tag_name: 'On Market', tag_name_ID: '020' },
      { tag_name: 'Normal Sale', tag_name_ID: '021' },
      { tag_name: 'LLC Lookup', tag_name_ID: '022' },
    ];
    
    const sampleEnabledTags = [
      { tag_name: 'Important', tag_name_ID: '001' },
      { tag_name: 'Gary', tag_name_ID: '002' },
      { tag_name: 'Motivated Seller', tag_name_ID: '005' },
      { tag_name: 'New Numbers', tag_name_ID: '008' },
      { tag_name: 'Followup', tag_name_ID: '014' },
      { tag_name: 'Unauthorized Occupant', tag_name_ID: '018' },

    ];
    
    setAllTags(propertyTagsSample);
    setEnabledTags(sampleEnabledTags);
  }, []);

  // Function to toggle the tag's enabled state
  const toggleTag = (tag) => {
    if (enabledTags.some(et => et.tag_name_ID === tag.tag_name_ID)) {
      // Tag is currently enabled, so disable it
      setEnabledTags(enabledTags.filter(et => et.tag_name_ID !== tag.tag_name_ID));
    } else {
      // Tag is currently disabled, so enable it
      setEnabledTags([...enabledTags, tag]);
    }
  };

  // Check if tag is enabled
  const isTagEnabled = (tag) => {
    return enabledTags.some(et => et.tag_name_ID === tag.tag_name_ID);
  };

  return (
    <>
    <WhiteBox>
      <Title>Property Tags</Title>
      {allTagsData.length > 0 ? (
        <Grid container spacing={1}>
          {allTagsData.map((tag) => (
            <Grid item xs={4} key={tag.tag_name_ID}>
              <Chip
                fullWidth // Make the Chip take up the full width of the grid item
                sx={{ marginRight: 1, marginTop: 1 }}
                label={tag.tag_name || "Temp Tag"}
                variant={isTagEnabled(tag) ? "filled" : "outlined"}
                color={isTagEnabled(tag) ? "info" : "default"}
                onClick={() => toggleTag(tag)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <div>No tags available</div>
      )}
      </WhiteBox>
    </>
  );
}

export default App;
